<template>
  <section class="catalog-page__goods-section" :class="{ loading: isLoading }">
    <v-loading :is-loading="isLoading"></v-loading>
    <div class="left-container">
      <custom-header
        :title="'Результаты поиска по запросу `' + activeSearchValue + '`'"
      ></custom-header>
      <div class="catalog-page__sort-view-block">
        <div class="sort-item" @click="sortByNameHandler()" :class="{ top: TopSortByName }">
          <span>По названию</span>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 11L12 16L17 11"
              stroke="#424F60"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="sort-item" @click="sortByPriceHandler()" :class="{ top: TopSortByPrice }">
          <span>По цене</span>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 11L12 16L17 11"
              stroke="#424F60"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <div class="view-block">
          <div class="view-item">
            <input
              type="radio"
              id="view-type-1"
              name="view-type"
              value="normal"
              checked
              @change="viewType = 'normal'"
            />
            <label for="view-type-1">
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  class="white-fill"
                  x="0.5"
                  y="0.5"
                  width="33"
                  height="33"
                  rx="5.5"
                  fill="white"
                />
                <g clip-path="url(#clip0_2275_57438)">
                  <mask
                    id="mask0_2275_57438"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="9"
                    y="9"
                    width="16"
                    height="16"
                  >
                    <path d="M25 9H9V25H25V9Z" fill="white" />
                  </mask>
                  <g mask="url(#mask0_2275_57438)">
                    <path
                      class="gray-fill"
                      d="M14.5248 9H10.8503C9.83003 9 9 9.83003 9 10.8503V14.5248C9 15.545 9.83003 16.375 10.8503 16.375H14.5248C15.545 16.375 16.375 15.545 16.375 14.5248V10.8503C16.375 9.83003 15.545 9 14.5248 9Z"
                      fill="#424F60"
                    />
                  </g>
                  <mask
                    id="mask1_2275_57438"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="9"
                    y="9"
                    width="16"
                    height="16"
                  >
                    <path d="M25 9H9V25H25V9Z" fill="white" />
                  </mask>
                  <g mask="url(#mask1_2275_57438)">
                    <path
                      class="gray-fill"
                      d="M23.125 9H19.5C18.4661 9 17.625 9.84113 17.625 10.875V14.5C17.625 15.5339 18.4661 16.375 19.5 16.375H23.125C24.1589 16.375 25 15.5339 25 14.5V10.875C25 9.84113 24.1589 9 23.125 9Z"
                      fill="#424F60"
                    />
                  </g>
                  <mask
                    id="mask2_2275_57438"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="9"
                    y="9"
                    width="16"
                    height="16"
                  >
                    <path d="M25 9H9V25H25V9Z" fill="white" />
                  </mask>
                  <g mask="url(#mask2_2275_57438)">
                    <path
                      class="gray-fill"
                      d="M14.5248 17.625H10.8503C9.83003 17.625 9 18.4551 9 19.4753V23.1497C9 24.17 9.83003 25 10.8503 25H14.5248C15.545 25 16.375 24.17 16.375 23.1497V19.4753C16.375 18.4551 15.545 17.625 14.5248 17.625Z"
                      fill="#424F60"
                    />
                  </g>
                  <mask
                    id="mask3_2275_57438"
                    style="mask-type: luminance"
                    maskUnits="userSpaceOnUse"
                    x="9"
                    y="9"
                    width="16"
                    height="16"
                  >
                    <path d="M25 9H9V25H25V9Z" fill="white" />
                  </mask>
                  <g mask="url(#mask3_2275_57438)">
                    <path
                      class="gray-fill"
                      d="M23.125 17.625H19.5C18.4661 17.625 17.625 18.4661 17.625 19.5V23.125C17.625 24.1589 18.4661 25 19.5 25H23.125C24.1589 25 25 24.1589 25 23.125V19.5C25 18.4661 24.1589 17.625 23.125 17.625Z"
                      fill="#424F60"
                    />
                  </g>
                </g>
                <rect x="0.5" y="0.5" width="33" height="33" rx="5.5" stroke="#424F60" />
                <defs>
                  <clipPath id="clip0_2275_57438">
                    <rect width="22" height="16" fill="white" transform="translate(6 9)" />
                  </clipPath>
                </defs>
              </svg>
            </label>
          </div>
          <div class="view-item">
            <input
              type="radio"
              id="view-type-2"
              name="view-type"
              value="plates"
              @change="viewType = 'plates'"
            />
            <label for="view-type-2">
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  class="white-fill"
                  x="0.5"
                  y="0.5"
                  width="33"
                  height="33"
                  rx="5.5"
                  fill="white"
                />
                <g clip-path="url(#clip0_2029_16859)">
                  <path
                    class="gray-fill"
                    d="M26.0664 8.62109H13.0898C12.0234 8.62109 11.1562 9.48863 11.1562 10.5547C11.1562 11.6212 12.0234 12.4883 13.0898 12.4883H26.0664C27.1325 12.4883 28 11.6212 28 10.5547C28 9.48863 27.1325 8.62109 26.0664 8.62109Z"
                    fill="#424F60"
                  />
                  <path
                    class="gray-fill"
                    d="M26.0664 15.0664H13.0898C12.0234 15.0664 11.1562 15.9339 11.1562 17C11.1562 18.0665 12.0234 18.9336 13.0898 18.9336H26.0664C27.1325 18.9336 28 18.0665 28 17C28 15.9339 27.1325 15.0664 26.0664 15.0664Z"
                    fill="#424F60"
                  />
                  <path
                    class="gray-fill"
                    d="M26.0664 21.5117H13.0898C12.0234 21.5117 11.1562 22.3793 11.1562 23.4453C11.1562 24.5118 12.0234 25.3789 13.0898 25.3789H26.0664C27.1325 25.3789 28 24.5118 28 23.4453C28 22.3793 27.1325 21.5117 26.0664 21.5117Z"
                    fill="#424F60"
                  />
                  <path
                    class="gray-fill"
                    d="M7.93359 8.62109C6.86711 8.62109 6 9.48863 6 10.5547C6 11.6212 6.86711 12.4883 7.93359 12.4883C8.99965 12.4883 9.86719 11.6212 9.86719 10.5547C9.86719 9.48863 8.99965 8.62109 7.93359 8.62109Z"
                    fill="#424F60"
                  />
                  <path
                    class="gray-fill"
                    d="M7.93359 15.0664C6.86711 15.0664 6 15.9339 6 17C6 18.0665 6.86711 18.9336 7.93359 18.9336C8.99965 18.9336 9.86719 18.0665 9.86719 17C9.86719 15.9339 8.99965 15.0664 7.93359 15.0664Z"
                    fill="#424F60"
                  />
                  <path
                    class="gray-fill"
                    d="M7.93359 21.5117C6.86711 21.5117 6 22.3793 6 23.4453C6 24.5118 6.86711 25.3789 7.93359 25.3789C8.99965 25.3789 9.86719 24.5118 9.86719 23.4453C9.86719 22.3793 8.99965 21.5117 7.93359 21.5117Z"
                    fill="#424F60"
                  />
                </g>
                <rect
                  x="0.5"
                  y="0.5"
                  class="gray-stroke"
                  width="33"
                  height="33"
                  rx="5.5"
                  stroke="#424F60"
                />
                <defs>
                  <clipPath id="clip0_2029_16859">
                    <rect width="22" height="22" fill="white" transform="translate(6 6)" />
                  </clipPath>
                </defs>
              </svg>
            </label>
          </div>
          <div class="view-item">
            <input
              type="radio"
              id="view-type-3"
              name="view-type"
              value="lines"
              @change="viewType = 'lines'"
            />
            <label for="view-type-3">
              <svg
                width="34"
                height="34"
                viewBox="0 0 34 34"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect
                  class="white-fill"
                  x="0.5"
                  y="0.5"
                  width="33"
                  height="33"
                  rx="5.5"
                  fill="white"
                />
                <g clip-path="url(#clip0_2029_16880)">
                  <path
                    class="gray-fill"
                    d="M27.0833 17.8978H6.91667C6.41066 17.8978 6 17.4871 6 16.9811C6 16.4751 6.41066 16.0645 6.91667 16.0645H27.0833C27.5893 16.0645 28 16.4751 28 16.9811C28 17.4871 27.5893 17.8978 27.0833 17.8978Z"
                    fill="#424F60"
                  />
                  <path
                    class="gray-fill"
                    d="M27.0833 10.87H6.91667C6.41066 10.87 6 10.4593 6 9.95329C6 9.44729 6.41066 9.03662 6.91667 9.03662H27.0833C27.5893 9.03662 28 9.44729 28 9.95329C28 10.4593 27.5893 10.87 27.0833 10.87Z"
                    fill="#424F60"
                  />
                  <path
                    class="gray-fill"
                    d="M27.0833 24.9251H6.91667C6.41066 24.9251 6 24.5145 6 24.0085C6 23.5025 6.41066 23.0918 6.91667 23.0918H27.0833C27.5893 23.0918 28 23.5025 28 24.0085C28 24.5145 27.5893 24.9251 27.0833 24.9251Z"
                    fill="#424F60"
                  />
                </g>
                <rect x="0.5" y="0.5" width="33" height="33" rx="5.5" stroke="#424F60" />
                <defs>
                  <clipPath id="clip0_2029_16880">
                    <rect
                      class="white-fill"
                      width="22"
                      height="22"
                      fill="white"
                      transform="translate(6 6)"
                    />
                  </clipPath>
                </defs>
              </svg>
            </label>
          </div>
        </div>
      </div>
      <div class="catalog-page__goods-container" :class="viewType">
        <product-card
          v-for="good in goods"
          :key="good.id"
          :id="good.id"
          :amount="good.amount"
          :articul="good.articul"
          :brand="good.brand"
          :multiplicity="good.multiplicity"
          :unit="good.unit"
          :name="good.name"
          :price="good.price"
          :images="good.images"
          :view="viewType"
          :attribute="good.attribute"
          :availability="good.availability"
          :added="good.added"
          @addedToCart="onAddedToCart"
          @amount-change="onAmountChange"
        ></product-card>
      </div>
      <v-pagination
        class="catalog-page__pagination"
        v-show="isPagination"
        :totalPages="getTotalPages"
        :currentPage="currentPage"
        @page-change="pageChange"
      ></v-pagination>
    </div>
    <div class="functional-block">
      <form class="filter-block" ref="filterForm">
        <p class="filter-block__header">Фильтр по параметрам</p>
        <div v-for="filter in filters" class="filter-item lines">
          <div class="filter-item__header" @click="showFilter">
            <p class="filter-item__name">{{ filter.header }}</p>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 11L12 16L17 11"
                stroke="#D93030"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <Transition>
            <ul class="filter-item__list">
              <template v-if="filter.type == 0">
                <li v-for="parameter in filter.options">
                  <v-checkbox
                    :checkbox-id="filter.id + parameter"
                    :checkbox-label="getLabel(parameter, filter.type)"
                    :checkbox-name="filter.id"
                    :checkbox-value="getLabel(parameter, filter.type)"
                    @changed="(id, status) => onFilterChanged(status, filter.id, parameter)"
                  ></v-checkbox>
                </li>
              </template>
            </ul>
          </Transition>
        </div>
        <div class="filter-item price" :class="{ show: isPriceList }">
          <div class="filter-item__header" @click="showFilterList('price')">
            <p class="filter-item__name">Цена</p>
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 11L12 16L17 11"
                stroke="#D93030"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </div>
          <Transition>
            <div v-show="isPriceList" class="filter-item__list">
              <input
                type="number"
                name="price-in"
                v-model="startPrice"
                placeholder="от"
                @input="priceInputHandler"
              />
              <p>-</p>
              <input
                type="number"
                name="price-out"
                v-model="endPrice"
                @input="isEndPriceError = false"
                @blur="priceInputHandler"
                placeholder="до"
                :class="{ error: isEndPriceError }"
              />
            </div>
          </Transition>
        </div>
        <div class="filter-block__buttons">
          <button class="filter-block__btn" type="button" @click="applyFilters">Показать</button>
          <button class="filter-block__btn gray" type="button" @click="resetFilters">
            Сбросить
          </button>
        </div>
      </form>
      <div class="functional-block__links">
        <Transition>
          <router-link
            :to="{ name: 'cart' }"
            v-show="isGoodsInCart"
            class="download-button cart-button"
            >Перейти в корзину</router-link
          >
        </Transition>
        <a class="download-button" :href="storePricelist" download target="_blank">
          <span>Скачать прайс-лист</span>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 12L12 17M12 17L17 12M12 17L12 4"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M6 20H18"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </a>
      </div>
      <p class="information-block">
        По умолчанию в каталоге отражены цены для корпоративных клиентов. Окончательные цены при
        оформления заказа могут отличаться. Индивидуальные скидки и специальные условия поставки
        согласовываются с менеджером.
      </p>
    </div>
  </section>
</template>

<script>
import AppHeader from '../../components/AppHeader.vue'
import SideMenu from '../../components/SideMenu.vue'
import AppBreadcrumb from '../../components/AppBreadcrumb.vue'
import MainSearch from '../../components/MainSearch.vue'
import vPopup from '../../components/v-popup.vue'
import ProductCard from '../../components/ProductCard.vue'
import CustomHeader from '../../components/CustomHeader.vue'
import vPagination from '../../components/v-pagination.vue'
import vCheckbox from '../../components/v-checkbox.vue'
import vLoading from '../../components/v-loading.vue'

import { mapState, mapWritableState } from 'pinia'
import { useUserToken } from '@/stores/userToken'
import { useAddedGoods } from '@/stores/addedGoods'
import { useGeneralInfo } from '../../stores/generalInfo'
import { useSearchValue } from '../../stores/searchValue'

import { serverRequest } from '../../api/request'

export default {
  name: 'SearchGoodsView',
  components: {
    AppHeader,
    SideMenu,
    AppBreadcrumb,
    MainSearch,
    vPopup,
    vPagination,
    CustomHeader,
    vCheckbox,
    vLoading,
    ProductCard
  },

  props: {
    searchValue: {
      type: String,
      default() {
        return ''
      }
    }
  },

  data() {
    return {
      breadcrumbList: [
        {
          path: 'search-goods',
          name: 'Поиск'
        }
      ],

      isLoading: false,
      isPriceList: false,

      filters: [],
      activeFilters: [],
      goods: [],
      startPrice: null,
      endPrice: null,
      isEndPriceError: false,

      viewType: 'square',

      sortByName: 'bottom',
      sortByPrice: 'bottom',
      activeSort: 5,

      currentPage: 1,
      goodsOnPage: 8,
      allGoodsCount: 1,
      activeSearchValue: ''
    }
  },

  beforeMount() {
    this.activeSearchValue = this.storeSearchValue

    this.getFilters()
    this.getGoods()
  },

  watch: {
    searchValue: {
      handler(val) {
        this.onSearch()
      }
    }
  },

  computed: {
    ...mapState(useUserToken, { userToken: 'token' }),
    ...mapState(useAddedGoods, {
      storeAddedGoods: 'cartGoods'
    }),
    ...mapState(useGeneralInfo, { storePricelist: 'priceList' }),
    ...mapWritableState(useSearchValue, { storeSearchValue: 'searchValue' }),

    TopSortByName() {
      if (this.sortByName == 'top') {
        return true
      } else {
        return false
      }
    },
    TopSortByPrice() {
      if (this.sortByPrice == 'top') {
        return true
      } else {
        return false
      }
    },

    getStartPrice() {
      if (this.startPrice == null) {
        return 0
      } else {
        return this.startPrice
      }
    },

    getEndPrice() {
      if (this.endPrice == null) {
        return null
      } else {
        return this.endPrice
      }
    },

    currentPosition() {
      return (this.currentPage - 1) * this.goodsOnPage
    },

    isPagination() {
      if (this.allGoodsCount > this.goodsOnPage) {
        return true
      } else {
        return false
      }
    },

    getTotalPages() {
      if (this.isPagination) {
        return Math.ceil(this.allGoodsCount / this.goodsOnPage)
      } else {
        return 1
      }
    },

    isGoodsInCart() {
      if (this.storeAddedGoods.length > 0) {
        return true
      } else {
        return false
      }
    }
  },

  methods: {
    onSearch() {
      this.activeSearchValue = this.searchValue
      this.currentPage = 1
      this.sortByName = 'bottom'
      this.sortByPrice = 'bottom'
      this.getGoods()
    },

    getLabel(label, type) {
      if (typeof label == 'string') {
        return label
      } else {
        if (label == true) {
          return 'Да'
        }
        if (label == false) {
          return 'Нет'
        }
      }
    },

    sortByNameHandler() {
      console.log(this.sortByName)
      this.sortByPrice = 'bottom'

      if (this.sortByName == 'top') {
        this.sortByName = 'bottom'
        this.activeSort = 4
      } else {
        this.sortByName = 'top'
        this.activeSort = 3
      }

      this.currentPage = 1
      this.getGoods()
    },

    sortByPriceHandler() {
      this.sortByName = 'bottom'
      if (this.sortByPrice == 'top') {
        this.sortByPrice = 'bottom'
        this.activeSort = 1
      } else {
        this.sortByPrice = 'top'
        this.activeSort = 2
      }

      this.currentPage = 1
      this.getGoods()
    },

    priceInputHandler() {
      if (this.endPrice != null) {
        if (this.startPrice > this.endPrice) {
          this.endPrice = null
          this.isEndPriceError = true
        } else {
          this.isEndPriceError = false
        }
      }
    },

    onFilterChanged(status, id, value) {
      console.log(status, id, value)

      let currentFilter = this.activeFilters.find((item) => item.id === id)
      if (currentFilter) {
        if (status == true) {
          currentFilter.options.push(value)
        } else {
          currentFilter.options = currentFilter.options.filter((item) => item != value)
          if (currentFilter.options.length == 0) {
            this.activeFilters = this.activeFilters.filter((item) => item.id != id)
          }
        }
      } else {
        currentFilter = {
          id: id,
          options: [value]
        }

        console.log(currentFilter)

        this.activeFilters.push(currentFilter)
      }

      console.log(this.activeFilters)
    },

    pageChange(page) {
      this.currentPage = page
      this.getGoods()
    },

    showFilter(event) {
      let item = event.target.closest('.filter-item')

      if (item.classList.contains('show')) {
        item.classList.remove('show')
      } else {
        item.classList.add('show')
      }
    },

    showFilterList(value) {
      if (value == 'diameter') {
        this.isDiameterList = !this.isDiameterList
      }

      if (value == 'country') {
        this.isCountryList = !this.isCountryList
      }

      if (value == 'brand') {
        this.isBrandList = !this.isBrandList
      }

      if (value == 'price') {
        this.isPriceList = !this.isPriceList
      }
    },

    resetFilters() {
      this.$refs.filterForm.reset()
      this.startPrice = null
      this.endPrice = null
    },

    onAddedToCart(id) {
      let good = this.goods.find((item) => item.id === id)
      good.added = !good.added
    },

    onAmountChange(id, amount) {
      console.log('change')
      let good = this.goods.find((item) => item.id === id)
      good.amount = amount
    },

    checkProducts(array) {
      for (let good of array) {
        let cartGood = this.storeAddedGoods.find((item) => item.id == good.id)

        if (cartGood != undefined) {
          good.added = true
          good.amount = cartGood.amount
        } else {
          good.added = false
        }
      }
    },

    async getGoods() {
      this.isLoading = true

      let formData = {
        sort: this.activeSort,
        price_start: this.getStartPrice,
        price_end: this.getEndPrice,
        filters: this.activeFilters,
        start_pos: this.currentPosition,
        search_value: this.activeSearchValue,
        amount: this.goodsOnPage
      }

      console.log(formData)

      let result = await serverRequest('POST', 'json', 'search_goods', formData)

      if (result != 'error' && result != 'unathorized') {
        this.allGoodsCount = result.all_goods_count
        this.goods = result.goods

        this.checkProducts(this.goods)

        this.isLoading = false
      } else {
        //alert('Ошибка запроса')
      }
    },

    async getFilters() {
      this.isLoading = true

      let formData = {
        category: 0
      }

      let result = await serverRequest('POST', 'json', 'get_catalog_filters', formData)

      if (result != 'error' && result != 'unathorized') {
        this.filters = result.filters

        this.isLoading = false
      } else {
        //alert('Ошибка запроса')
      }
    },

    applyFilters() {
      this.getGoods()
    }
  }
}
</script>

<style lang="scss">
.catalog-page {
  &__pagination {
    margin-top: 32px;
  }

  .cart-button {
    margin-top: 0;
  }

  .information-block {
    margin-top: 24px;

    padding: 12px 16px;

    @extend %text-regular;
    color: var(--bw0);

    border-radius: 4px;
    background-color: var(--bw4);
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.3s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
}
</style>
