<template>
  <div class="order-page" :class="{ loading: isLoading }">
    <v-loading :is-loading="isLoading" :color="'white'"></v-loading>
    <custom-header :title="'Запрос КП'" :line="'fit'"></custom-header>
    <p class="order-page__number">Предзаказ №{{ storeOrderNumber }} от {{ storeOrderDate }}</p>
    <div class="order-table-container">
      <table class="order-table">
        <tr>
          <th class="first name">Название</th>
          <th class="articul">Артикул</th>
          <th class="amount">Количество</th>
          <th class="unit">Ед. изм.</th>
          <th class="price">Цена</th>
          <th class="price">Стоимость</th>
          <th class="price">Наличие</th>
          <th class="last" colspan="2">Поставка</th>
        </tr>
        <order-row
          v-for="good in storeOrderProducts"
          :id="good.id"
          :name="good.name"
          :articul="good.articul"
          :brand="good.brand"
          :multiplicity="good.multiplicity"
          :unit="good.unit"
          :price="good.price"
          :availability="good.availability"
          :amount="good.amount"
          :supply="good.supply"
          :deleted="good.deleted"
          @orderItemDeleted="onGoodDelete"
          @amountChange="onAmountChange"
          @orderItemRecovery="onItemRecovery"
          @orderItemDeficit="onOrderItemDeficit"
          @deficitAmountChange="onDeficitAmountChange"
        ></order-row>
      </table>
    </div>
    <!-- <div v-show="haveDeficit" class="аvailability-alert alert">
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
          stroke="#D93030"
          stroke-width="2"
          stroke-miterlimit="10"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M13.5 17.1001C13.5 17.9285 12.8284 18.6001 12 18.6001C11.1716 18.6001 10.5 17.9285 10.5 17.1001C10.5 16.2717 11.1716 15.6001 12 15.6001C12.8284 15.6001 13.5 16.2717 13.5 17.1001Z"
          fill="#D93030"
        />
        <path
          d="M12 13.2002L12 6.6002"
          stroke="#D93030"
          stroke-width="2"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
      <p>Некоторых из выбранных вами товаров нет в наличии</p>
    </div>
    <div v-show="haveDeficit" class="аvailability-options">
      <div class="option">
        <div
          @click="setActiveSolutionOption('pickAnalog')"
          class="option__content"
          :class="{ active: pickAnalog }"
        >
          <p>Подобрать аналоги</p>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 11L12 16L17 11"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <info-tooltip
          :text="'Подберите аналоги из подборки предложенных товаров, чтобы не ждать поставки товаров, которых нет в наличии на текущий момент.'"
        ></info-tooltip>
      </div>
      <div class="option">
        <div
          @click="setActiveSolutionOption('awaitSupply')"
          class="option__content"
          :class="{ active: awaitSupply }"
        >
          <p>Условия поставки</p>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 11L12 16L17 11"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <info-tooltip
          :text="'Выберите удобный порядок поставки в зависимости от наличия позиций'"
        ></info-tooltip>
      </div>
      <div class="option">
        <div
          @click="setActiveSolutionOption('partShipment')"
          class="option__content"
          :class="{ active: partShipment }"
        >
          <p>Частичный заказ</p>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7 11L12 16L17 11"
              stroke="white"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        <info-tooltip :text="'Заказать только позиции в наличии'"></info-tooltip>
      </div>
    </div> -->
    <section class="solving-section" v-if="pickAnalog">
      <div class="replacements-container">
        <replacement-item
          v-for="good in deficitGoods"
          :key="good.id"
          :good="good"
          @save-choice="OnSaveChoice"
        ></replacement-item>
      </div>
    </section>
    <div class="info-container">
      <div class="info-container__left">
        <section class="wait-supply" v-if="awaitSupply">
          <h5>Условия поставки</h5>
          <v-radio
            v-for="(option, number) in supplyOptions"
            :checked="option.checked"
            :name="'supply'"
            :id="'supply-option' + number"
            :label="option.label"
            :value="option.value"
            @changed="onSupplyRadioChanged"
          ></v-radio>
          <!-- <v-checkbox
            class="splice-bill"
            :checkbox-id="'splice-bill'"
            :checkbox-name="'splice-bill'"
            :checkbox-label="'Разбить на 2 счета по наличию'"
            :checkbox-value="'splice-bill'"
            @changed="onSpliceCheckboxChanged"
          ></v-checkbox> -->
        </section>
        <section class="wait-supply" v-if="partShipment">
          <h5>Условия поставки</h5>
          <v-radio
            v-for="(option, number) in partOrderOptions"
            :checked="option.checked"
            :name="'part-supply'"
            :id="'part-supply-option' + number"
            :label="option.label"
            :value="option.value"
          ></v-radio>
          <!-- <v-checkbox
            class="splice-bill"
            :checkbox-id="'splice-bill'"
            :checkbox-name="'splice-bill'"
            :checkbox-label="'Разбить на 2 счета по наличию'"
            :checkbox-value="'splice-bill'"
            @changed="onSpliceCheckboxChanged"
          ></v-checkbox> -->
        </section>
        <div class="delivery-block">
          <h5>Условия доставки</h5>
          <v-radio
            v-for="(option, number) in deliveryOptions"
            :checked="option.checked"
            :name="'delivery'"
            :id="'delivery-option' + number"
            :label="option.label"
            :value="option.value"
            @changed="onRadioChanged"
          ></v-radio>
          <div
            class="input-container address-input"
            v-if="isAddressRequired"
            :class="{ error: v$.address.$errors.length }"
          >
            <label for="delivery-address">Адрес доставки</label>
            <input
              v-model="address"
              id="delivery-address"
              type="text"
              placeholder="Введите адрес"
            />
          </div>
          <v-checkbox
            v-if="isAddressRequired"
            class="remember-address"
            :checkbox-id="'remember-address'"
            :checkbox-name="'remember-address'"
            :checkbox-label="'Запомнить адрес для будущих доставок'"
            :checkbox-value="'remember-address'"
            @changed="onAddressCheckboxChanged"
          ></v-checkbox>
          <v-select
            v-show="isOhterTownDelivery"
            class="select"
            :select-id="1"
            :select-name="'Транспортная компания'"
            :options="transportCompanyOptions"
            :selected="transportCompanyValue.label"
            @select="transportCompanySelectHandler"
          ></v-select>
          <div v-show="isOhterTownDelivery" class="delivery-alert alert">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 21C16.9706 21 21 16.9706 21 12C21 7.02944 16.9706 3 12 3C7.02944 3 3 7.02944 3 12C3 16.9706 7.02944 21 12 21Z"
                stroke="#D93030"
                stroke-width="2"
                stroke-miterlimit="10"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M13.5 17.1001C13.5 17.9285 12.8284 18.6001 12 18.6001C11.1716 18.6001 10.5 17.9285 10.5 17.1001C10.5 16.2717 11.1716 15.6001 12 15.6001C12.8284 15.6001 13.5 16.2717 13.5 17.1001Z"
                fill="#D93030"
              />
              <path
                d="M12 13.2002L12 6.6002"
                stroke="#D93030"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
            <p>
              Доставка осуществляется за счет клиента. Включена бесплатная доставка до терминала ТК.
            </p>
          </div>
          <div class="text-area">
            <label for="comment">Комментарий к заказу</label>
            <textarea
              v-model="comment"
              id="comment"
              type="text"
              placeholder="Введите текст"
            ></textarea>
          </div>
        </div>
      </div>
      <section class="cart-info order-info">
        <div class="cart-info__row">
          <p class="cart-info__row__label">Товаров</p>
          <p class="cart-info__row__value">{{ getAllGoodAmount }}</p>
        </div>
        <div class="cart-info__row">
          <p class="cart-info__row__label">Сумма</p>
          <p class="cart-info__row__value">{{ parseInt(getFullSum).toLocaleString('ru-RU') }} ₽</p>
        </div>
        <!-- <div class="cart-info__bottom">
          <v-checkbox
            :checkbox-id="'printed_catalog'"
            :checkbox-name="'printed-catalog'"
            :checkbox-label="'Запросить печатный каталог'"
            :checkbox-value="'need-catalog'"
            @changed="onCheckboxChanged"
          ></v-checkbox>
        </div>
        <p class="order-info__subtext">
          Изменения стоимости будут внесены на этапе формирования счета
        </p> -->
        <div class="order-info__btns">
          <button
            v-if="!isCommercialOfferFormed"
            @click="formCommercialOffer"
            class="order-info__download-cp"
            :class="{ loading: isCommercialOfferLoading }"
          >
            Сформировать КП
            <v-loading
              :isLoading="isCommercialOfferLoading"
              :color="'gray'"
              :size="'little'"
            ></v-loading>
          </button>
          <template v-else>
            <a
              :href="storeCommercialOffer"
              target="_blank"
              :download="commercialOfferName"
              class="order-info__download-cp"
            >
              <span>Скачать КП</span>
              <svg
                width="25"
                height="24"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 12L12.5 17M12.5 17L17.5 12M12.5 17L12.5 4"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M6.5 20H18.5"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
            <a
              :href="storeCommercialOffer"
              target="_blank"
              :download="commercialOfferName"
              class="order-info__print"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M7.5 18H5C3.89543 18 3 17.186 3 16.1818V9.81818C3 8.81403 3.89543 8 5 8H19C20.1046 8 21 8.81403 21 9.81818V16.1818C21 17.186 20.1046 18 19 18H16.5"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linejoin="round"
                />
                <path
                  d="M7 3H17V8H7V3Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linejoin="round"
                />
                <path
                  d="M17 11H13"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7 15H17V21H7V15Z"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </template>
        </div>
        <!-- <button
          class="cart-info__button"
          @click="OnOrderClick"
          :class="{
            'not-solved': !isDeficitSolved,
            'not-formed': !isCommercialOfferFormed && isDeficitSolved
          }"
        >
          {{ haveDeficit ? 'Отправить заявку' : 'Сформировать счет' }}
        </button> -->
        <button
          class="cart-info__button"
          @click="OnOrderClick"
          :class="{
            'not-formed': !isCommercialOfferFormed
          }"
        >
          {{ haveDeficit ? 'Отправить заявку' : 'Сформировать счет' }}
        </button>
      </section>
    </div>
  </div>
</template>

<script>
import AppHeader from '../components/AppHeader.vue'
import SideMenu from '../components/SideMenu.vue'
import AppBreadcrumb from '../components/AppBreadcrumb.vue'
import ProductCard from '../components/ProductCard.vue'
import CustomHeader from '../components/CustomHeader.vue'
import AmountBlock from '../components/AmountBlock.vue'
import vCheckbox from '../components/v-checkbox.vue'
import OrderRow from '../components/OrderRow.vue'
import InfoTooltip from '../components/InfoTooltip.vue'
import vRadio from '../components/v-radio.vue'
import vSelect from '../components/v-select.vue'
import ReplacementItem from '../components/ReplacementItem.vue'
import vLoading from '../components/v-loading.vue'

import { mapWritableState, mapState, mapActions } from 'pinia'
import { useAddedGoods } from '@/stores/addedGoods'
import { useUserToken } from '@/stores/userToken'
import { useOrderInfo } from '@/stores/orderInfo'
import { useBreadcrumbs } from '../stores/breadcrumbs'

import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'

import { serverRequest } from '../api/request'

import _ from 'lodash'

export default {
  name: 'CartView',
  components: {
    AppHeader,
    SideMenu,
    AppBreadcrumb,
    ProductCard,
    CustomHeader,
    AmountBlock,
    vCheckbox,
    OrderRow,
    InfoTooltip,
    vRadio,
    vSelect,
    ReplacementItem,
    vLoading
  },

  data() {
    return {
      v$: useVuelidate(),

      isLoading: false,
      breadcrumbList: [
        {
          path: 'cart',
          name: 'Корзина'
        },
        {
          path: 'categories',
          name: 'Каталог товаров'
        },
        {
          path: 'cart',
          name: 'Корзина'
        },
        {
          path: 'order',
          name: 'Оформление заказа'
        }
      ],
      orderGoods: [
        // {
        //   id: 1,
        //   amount: 1,
        //   articul: 'FBK0500219',
        //   name: "Корончатое сверло 1 (фреза кольцевая) Ø13 L=30/63 мм №102 WELDON19 HSS M2 ТМ ПрофОснастка",
        //   price: 1496,
        //   image: "good.webp",
        //   аvailability: 10,
        //   unit: 'шт',
        //   supply: '20/01/2020',
        //   deleted: false
        // },
        // {
        //   id: 2,
        //   amount: 1,
        //   articul: 'FBK0500219',
        //   name: "Корончатое сверло 2 (фреза кольцевая) Ø13 L=30/63 мм №102 WELDON19 HSS M2 ТМ ПрофОснастка",
        //   price: 1496,
        //   image: "good.webp",
        //   аvailability: 5,
        //   unit: 'шт',
        //   supply: '20/01/2020',
        //   deleted: false
        // },
        // {
        //   id: 3,
        //   amount: 1,
        //   articul: 'FBK0500219',
        //   name: "Корончатое сверло 3 (фреза кольцевая) Ø13 L=30/63 мм №102 WELDON19 HSS M2 ТМ ПрофОснастка",
        //   price: 1496,
        //   image: "good.webp",
        //   аvailability: 10,
        //   unit: 'шт',
        //   supply: '20/01/2020',
        //   deleted: false
        // },
        // {
        //   id: 4,
        //   amount: 1,
        //   articul: 'FBK0500219',
        //   name: "Корончатое сверло 4 (фреза кольцевая) Ø13 L=30/63 мм №102 WELDON19 HSS M2 ТМ ПрофОснастка",
        //   price: 1496,
        //   image: "good.webp",
        //   аvailability: 10,
        //   unit: 'шт',
        //   supply: '20/01/2020',
        //   deleted: false
        // },
        // {
        //   id: 5,
        //   amount: 1,
        //   articul: 'FBK0500219',
        //   name: "Корончатое сверло 5 (фреза кольцевая) Ø13 L=30/63 мм №102 WELDON19 HSS M2 ТМ ПрофОснастка",
        //   price: 1496,
        //   image: "good.webp",
        //   аvailability: 10,
        //   unit: 'шт',
        //   supply: '20/01/2020',
        //   deleted: false
        // },
      ],
      supplyOptions: [
        {
          label: 'Ожидать поставку и отгрузить в полном объеме',
          value: '1',
          checked: true
        },
        {
          label: 'Отгрузить по наличии и ожидать поставки',
          value: '2',
          checked: false
        },
        {
          label: 'Разбить на 2 заказа по наличию',
          value: '3',
          checked: false
        }
      ],
      partOrderOptions: [
        {
          label: 'Заказать только позиции в наличии',
          value: '1',
          checked: true
        }
      ],
      deliveryOptions: [
        {
          label: 'Самовывоз',
          value: '1',
          checked: true
        },
        {
          label: 'Доставка по Москве',
          value: '2',
          checked: false
        },
        {
          label: 'Доставка в другой регион',
          value: '3',
          checked: false
        }
      ],
      transportCompanyOptions: [
        {
          name: 'Деловые линии',
          value: 'dellin'
        },
        {
          name: 'СДЭК',
          value: 'sdek'
        },
        {
          name: 'Грузовичкоф',
          value: 'grzoff'
        },
        {
          name: 'Газелькин',
          value: 'gzlkn'
        }
      ],

      initOrderGoods: [],

      deficitGoods: [],

      isCommercialOfferFormed: false,

      isCommercialOfferFormedByUser: false,

      transportCompanyValue: {
        label: 'Деловые линии',
        value: 'dellin'
      },
      delivery_in_price: false,
      remember_address: false,

      // spliceCheck: false,
      address: '',
      comment: '',
      deliveryType: 1,

      activeSolution: null,

      pickAnalog: false,
      awaitSupply: false,
      partShipment: false,

      isCommercialOfferLoading: false,
      commercialOfferName: '',

      timeOut: null
    }
  },

  watch: {
    storeOrderProducts: {
      handler(newItems, oldItems) {
        if (
          this.arraysAreEqual(newItems, this.initOrderGoods) &&
          this.isCommercialOfferFormedByUser
        ) {
          this.isCommercialOfferFormed = true
        } else {
          this.isCommercialOfferFormed = false
        }
        this.previousItems = _.cloneDeep(newItems)
      },
      deep: true
    }
  },

  validations() {
    if (this.isAddressRequired) {
      return {
        address: { required }
      }
    }
  },

  beforeMount() {
    this.storeBreadcrumbs = [
      {
        path: 'categories',
        name: 'Каталог'
      },
      {
        path: 'cart',
        name: 'Корзина'
      },
      {
        path: 'order',
        name: 'Оформление заказа'
      }
    ]
  },

  mounted() {
    this.initOrderGoods = _.cloneDeep(this.storeOrderProducts)
    // this.checkCommercialOffer()
  },

  beforeUnmount() {
    clearTimeout(this.timeOut)
  },

  computed: {
    ...mapState(useUserToken, { userToken: 'token' }),
    ...mapWritableState(useOrderInfo, { storeOrderNumber: 'orderNumber' }),
    ...mapWritableState(useOrderInfo, { storeOrderDate: 'orderDate' }),
    ...mapWritableState(useOrderInfo, { storeOrderProducts: 'orderProducts' }),
    ...mapWritableState(useOrderInfo, { storeCommercialOffer: 'сommercialOffer' }),
    ...mapWritableState(useBreadcrumbs, { storeBreadcrumbs: 'breadcrumbs' }),

    getAllGoodAmount() {
      let amount = 0
      this.storeOrderProducts.forEach((item) => {
        if (!item.deleted) {
          amount += item.amount
        }
      })

      return amount
    },

    haveDeficit() {
      if (this.deficitGoods.length > 0) {
        return true
      } else {
        this.pickAnalog = false
        this.awaitSupply = false
        this.partShipment = false
        return false
      }
    },

    isOhterTownDelivery() {
      if (this.deliveryType == 3) {
        return true
      } else {
        return false
      }
    },

    isAddressRequired() {
      if (this.deliveryType == 1) {
        return false
      } else {
        return true
      }
    },

    getFullSum() {
      let sum = 0
      this.storeOrderProducts.forEach((item) => {
        if (!item.deleted) {
          sum += item.amount * item.price
        }
      })

      return sum
    },

    isDeficitSolved() {
      if (this.haveDeficit) {
        if (this.pickAnalog == true || this.awaitSupply == true || this.partShipment == true) {
          return true
        } else {
          return false
        }
      } else {
        return true
      }
    }
  },

  methods: {
    ...mapActions(useOrderInfo, { storeSetAmount: 'setAmount' }),
    ...mapActions(useOrderInfo, { storeReplaceGood: 'replaceGood' }),

    arraysAreEqual(array1, array2) {
      if (array1.length !== array2.length) {
        return false
      }

      for (let i = 0; i < array1.length; i++) {
        if (!_.isEqual(array1[i], array2[i])) {
          return false
        }
      }

      return true
    },

    async formCommercialOffer() {
      this.initOrderGoods = _.cloneDeep(this.storeOrderProducts)

      await this.getCommercialOffer()

      this.isCommercialOfferFormedByUser = true
      this.isCommercialOfferFormed = true
    },

    onAmountChange(id, amount) {
      this.storeSetAmount(id, amount)
    },

    onGoodDelete(id) {
      let good = this.storeOrderProducts.find((item) => item.id === id)
      good.deleted = true
    },

    onItemRecovery(id) {
      let good = this.storeOrderProducts.find((item) => item.id === id)
      good.deleted = false
    },

    onRadioChanged(value) {
      let option = this.deliveryOptions.find((item) => item.checked === true)
      option.checked = false

      option = this.deliveryOptions.find((item) => item.value === value)
      option.checked = true

      this.deliveryType = value

      console.log(this.deliveryType)
    },

    onSupplyRadioChanged(value) {
      let option = this.supplyOptions.find((item) => item.checked === true)
      option.checked = false

      option = this.supplyOptions.find((item) => item.value === value)
      option.checked = true
    },

    // checkCommercialOffer() {
    //   if (this.isCommercialOfferLoading) {
    //     this.timeOut = setTimeout(() => {
    //       this.getCommercialOffer()
    //     }, 5000)
    //   }
    // },

    onAddressCheckboxChanged(id, status) {
      console.log(status)

      this.remember_address = status
    },

    // onSpliceCheckboxChanged(id, status) {
    //   this.spliceCheck = status
    // },

    onCheckboxChanged(id, status) {
      console.log(status)
      this.delivery_in_price = status
    },

    transportCompanySelectHandler(option) {
      this.transportCompanyValue.label = option.name
      this.transportCompanyValue.value = option.value
    },

    onOrderItemDeficit(id, value) {
      if (value) {
        let good = this.storeOrderProducts.find((item) => item.id === id)

        let deficitItem = {
          id: good.id,
          amount: good.amount,
          articul: good.articul,
          name: good.name
        }

        this.deficitGoods.push(deficitItem)
      } else {
        this.deficitGoods = this.deficitGoods.filter((item) => {
          return item.id !== id
        })
      }
    },

    onDeficitAmountChange(id, amount) {
      console.log(amount)

      let deficitGood = this.deficitGoods.find((item) => item.id === id)
      deficitGood.amount = amount
    },

    OnSaveChoice(id, newGood) {
      this.deficitGoods = this.deficitGoods.filter((item) => {
        return item.id !== id
      })

      let good = this.storeOrderProducts.find((item) => item.id === id)

      //временный новый id для того, чтобы кол-во отрабатовало корректно
      let newId = id + newGood.id
      good.id = newId
      good.name = newGood.name
      good.articul = newGood.articul
      good.price = newGood.price
      good.amount = newGood.amount
      good.аvailability = 100000

      storeReplaceGood(id, newId, newGood.amount)
    },

    setActiveSolutionOption(value) {
      if (value == 'pickAnalog') {
        this.pickAnalog = true
        this.awaitSupply = false
        this.partShipment = false
      }
      if (value == 'awaitSupply') {
        this.pickAnalog = false
        this.awaitSupply = true
        this.partShipment = false
      }
      if (value == 'partShipment') {
        this.pickAnalog = false
        this.awaitSupply = false
        this.partShipment = true
      }
    },

    createGoodsList() {
      let goods = []

      for (let item of this.storeOrderProducts) {
        if (item.deleted == false || item.deleted == undefined || item.deleted == null) {
          let newGood = {
            id: '',
            amount: 0
          }
          newGood.id = item.id
          newGood.amount = item.amount
          goods.push(newGood)
        }
      }

      return goods
    },

    async getCommercialOffer() {
      this.isCommercialOfferLoading = true

      let goods = this.createGoodsList()

      let supplyOption = this.supplyOptions.find((item) => item.checked === true).value
      let split = false

      if (this.partShipment || parseInt(supplyOption) === 3) {
        split = true
      }

      let formData = {
        order_id: this.storeOrderNumber,
        goods: goods,
        split: split
      }

      let result = await serverRequest('POST', 'json', 'create_order_commercial_offer', formData)

      const byteString = atob(result.file)
      const arrayBuffer = new ArrayBuffer(byteString.length)
      const uint8Array = new Uint8Array(arrayBuffer)

      for (let i = 0; i < byteString.length; i++) {
        uint8Array[i] = byteString.charCodeAt(i)
      }

      const blob = new Blob([uint8Array], { type: 'application/octet-stream' })
      const url = URL.createObjectURL(blob)

      this.storeCommercialOffer = url
      this.commercialOfferName = result.fileName

      this.isCommercialOfferLoading = false

      // this.checkCommercialOffer()
    },

    async OnOrderClick() {
      this.v$.$validate()

      // if (this.isDeficitSolved) {
      // if (!this.haveDeficit) {
      if (!this.v$.$error) {
        console.log('no errors')

        this.isLoading = true

        let goods = []

        let availability = 'none'

        let supply_terms = 0

        if (this.awaitSupply) {
          availability = 'await'

          let supplyOption = this.supplyOptions.find((item) => item.checked === true)
          supply_terms = parseInt(supplyOption.value)
        }

        if (this.partShipment) {
          availability = 'partial'
        }

        for (let item of this.storeOrderProducts) {
          if (item.deleted == false || item.deleted == undefined || item.deleted == null) {
            let newGood = {
              id: '',
              amount: 0
            }
            newGood.id = item.id
            newGood.amount = item.amount
            goods.push(newGood)
          }
        }

        console.log(goods)

        let formData = {
          order_id: this.storeOrderNumber,
          address: this.address,
          availability_solution: availability,
          supply_terms: supply_terms,
          // split_bill: this.spliceCheck,
          remember_address: this.remember_address,
          deliveryType: this.deliveryType,
          comment: this.comment,
          transport_company: this.transportCompanyValue.value,
          need_catalog: this.delivery_in_price,
          products: goods
        }

        let result = await serverRequest('POST', 'json', 'form_order', formData)

        if (result != 'error' && result != 'unathorized') {
          let orderNumber = this.storeOrderNumber
          this.storeOrderProducts = []
          this.storeOrderNumber = ''
          this.storeOrderDate = ''

          this.$router.push({ name: 'order-item', params: { id: orderNumber } })
        } else {
          //alert('Ошибка запроса')
        }
      }
      // } else {
      //   console.log('have deficit')
      // }
    }
  }
}
</script>

<style lang="scss">
.order-page {
  @extend %white-bg-block;

  position: relative;

  width: 100%;

  &.loading {
    pointer-events: none;
  }

  &__number {
    @extend %H2;
    color: var(--bw0);

    margin-top: 32px;
  }

  .order-table-container {
    margin-top: 20px;

    border-radius: 6px 6px 0 0;
    border: 1px solid var(--bw3);
    background-color: var(--bw6);
    border-bottom: none;
  }

  .order-table {
    width: 100%;

    border-collapse: collapse;

    th {
      padding: 12px 40px 12px 40px;

      border-right: 1px solid var(--bw3);
      border-bottom: 1px solid var(--bw3);

      @extend %text-regular;
      color: var(--bw0);

      text-align: left;

      &.first {
        padding: 12px 26px 12px 26px;

        border-radius: 6px 0 0 0;
      }

      &.name {
        width: 340px;
        padding-left: 8px;
      }

      &.amount {
        padding: 12px 32px;
      }

      &.unit {
        text-align: center;
        padding: 12px 10px;
        width: 120px;
      }

      &.articul {
        padding-left: 26px;
      }

      &.last {
        border-right: none;
        width: 48px;
        padding: 12px 12px;
      }
    }
  }

  .аvailability-alert {
    margin-top: 24px;
  }

  .аvailability-options {
    display: flex;
    align-items: center;
    column-gap: 12px;

    margin-top: 16px;

    .option {
      display: flex;
      align-items: center;
      column-gap: 4px;

      &__content {
        display: flex;
        align-items: center;
        column-gap: 4px;

        border-radius: 6px;
        background-color: var(--bw1);

        cursor: pointer;

        p {
          @extend %text-regular;
          color: var(--bw6);
        }

        padding: 6px 12px;

        transition: background-color 0.3s;

        &.active {
          background-color: var(--bw1-light);
        }
      }
    }
  }

  .solving-section {
    margin-top: 48px;
  }

  .replacements-container {
    display: flex;
    flex-direction: column;
    row-gap: 16px;

    .replacement-item {
      &:last-of-type {
        border-bottom: none;
      }
    }
  }

  .wait-supply {
    margin-bottom: 32px;

    h5 {
      @extend %text-medium;
      color: var(--bw0);
    }

    .v-radio {
      margin-top: 12px;
    }

    .splice-bill {
      margin-top: 24px;
    }
  }

  .info-container {
    margin-top: 48px;

    display: flex;
    justify-content: space-between;

    &__left {
      width: 100%;
      max-width: 800px;
    }
  }

  .delivery-block {
    h5 {
      @extend %text-medium;
      color: var(--bw0);
    }

    .v-radio {
      margin-top: 12px;
    }
  }

  .input-container {
    @extend %input-container;

    label {
      font-weight: 600;
      color: var(--bw0);
    }
  }

  .address-input {
    margin-top: 32px;
  }

  .remember-address {
    margin-top: 16px;
  }

  .select {
    margin-top: 32px;

    max-width: 480px;
  }

  .v-select__label {
    font-weight: 600;
  }

  .delivery-alert {
    margin-top: 16px;

    max-width: 480px;
  }

  .text-area {
    margin-top: 32px;

    @extend %text-area;
  }

  .cart-info {
    margin: 0;

    height: fit-content;
    max-width: 400px;
  }

  .order-info {
    &__subtext {
      padding-left: 26px;

      margin-top: 8px;

      @extend %text-regular;
      color: var(--bw1);
    }

    &__btns {
      display: flex;
      column-gap: 12px;

      margin-top: 24px;

      a,
      button {
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 12px;

        @extend %text-regular;
        color: var(--bw6);

        background-color: var(--bw1);

        border-radius: 4px;

        padding: 12px;
      }
    }

    &__download-cp {
      width: 100%;
      position: relative;

      &.loading {
        pointer-events: none;
      }
    }

    &__print {
      position: relative;

      &.loading {
        pointer-events: none;
      }
    }
  }

  .cart-info__button {
    &.not-solved {
      opacity: 0.7;
      cursor: not-allowed;

      position: relative;

      &::after {
        position: absolute;

        content: 'Выберите опцию действия';
        display: block;

        @extend %text-regular;
        color: red;

        left: 0;
        bottom: -24px;

        opacity: 0;

        transition: opacity 0.5s;
      }

      &:hover {
        &::after {
          opacity: 1;
        }
      }
    }

    &.not-formed {
      opacity: 0.7;
      cursor: not-allowed;

      position: relative;

      &::after {
        position: absolute;

        content: 'Нужно сформировать КП';
        display: block;

        @extend %text-regular;
        color: red;

        left: 0;
        bottom: -24px;

        opacity: 0;

        transition: opacity 0.5s;
      }

      &:hover {
        &::after {
          opacity: 1;
        }
      }
    }
  }
}

.alert {
  display: flex;
  align-items: center;
  column-gap: 12px;

  padding: 12px 16px;

  background-color: var(--bw4);

  border-radius: 4px;

  width: fit-content;

  svg {
    flex-shrink: 0;
  }

  p {
    @extend %text-regular;
    color: var(--bw0);
  }
}

@media screen and (max-width: 1600px) {
  .order-page {
    .order-table {
      width: 100%;

      border-collapse: collapse;

      th {
        padding: 12px 20px 12px 20px;

        &.first {
          padding: 12px 26px 12px 26px;

          border-radius: 6px 0 0 0;
        }

        &.name {
          width: 240px;
          padding-left: 8px;
        }

        &.amount {
          padding: 12px 22px;
          text-align: center;
        }

        &.unit {
          text-align: center;
          padding: 12px 10px;
          width: 80px;
        }

        &.price {
          text-align: center;
        }

        &.articul {
          padding-left: 12px;
        }

        &.last {
          border-right: none;
          width: 48px;
          padding: 12px 12px;
        }
      }
    }

    .info-container {
      column-gap: 24px;
    }

    .order-info {
      &__btns {
        a,
        button {
          padding: 10px 12px;
          max-height: 40px;
        }
      }

      .cart-info__button {
        margin-top: 16px;
      }
    }
  }
}

@media screen and (max-width: 1300px) {
  .order-page {
    .order-table-container {
      display: none;
    }

    .delivery-block {
      max-width: 512px;

      .v-select {
        max-width: 100%;
      }

      .delivery-alert {
        max-width: 100%;
      }
    }
  }
}

@media screen and (max-width: 800px) {
  .order-page {
    .info-container {
      flex-direction: column;
      row-gap: 32px;
    }

    .delivery-block {
      max-width: 100%;
    }

    .cart-info {
      max-width: 100%;
    }
  }
}
</style>
