<template>
  <section class="products-slider">
    <swiper class="products-slider__slider" :slides-per-view="slides" :space-between="30">
      <swiper-slide v-for="good in products">
        <product-card
          :key="good.id"
          :id="good.id"
          :amount="good.amount"
          :name="good.name"
          :articul="good.articul"
          :brand="good.brand"
          :multiplicity="good.multiplicity"
          :unit="good.unit"
          :price="good.price"
          :attribute="good.attribute"
          :images="good.images"
          :availability="good.availability"
        ></product-card>
      </swiper-slide>
    </swiper>
  </section>
</template>

<script>
import { ref } from 'vue'
import { Swiper, SwiperSlide } from 'swiper/vue'
import ProductCard from '../components/ProductCard.vue'

import 'swiper/css'

export default {
  name: 'ProductSlider',

  components: {
    Swiper,
    SwiperSlide,
    ProductCard
  },

  props: {
    products: {
      type: Array,
      default() {
        return []
      }
    }
  },

  data() {
    return {
      slides: 5
    }
  },

  beforeMount() {
    if (window.innerWidth < 1600) {
      this.slides = 4
    }
  },

  methods: {}
}
</script>

<style lang="scss">
.products-slider {
  //width: 100%;
  min-width: 0;
  flex-shrink: 0;

  &__slider {
    //width: 100%;
    min-width: 0;

    .swiper-slide {
      // width: fit-content !important;
    }
  }

  .product-card {
    max-width: 322px !important;
  }
}
</style>
