<template>
  <section class="bills-block">
    <div class="filter-container">
      <div class="search-input-container">
        <input
          type="text"
          v-model="searchValue"
          placeholder="Введите номер счёта, номер заказа или статус"
          @keypress.enter="searchBills"
          @blur="blurHandler"
        />
        <svg
          @click="searchBills"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M21 21L16.6569 16.6569M16.6569 16.6569C18.1046 15.2091 19 13.2091 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19C13.2091 19 15.2091 18.1046 16.6569 16.6569Z"
            stroke="#424F60"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
          />
        </svg>
      </div>
      <v-calendar @date-change="onDateChange" @date-clear="onDateClear" :is-range="true">
        <div class="date-pick">
          <span v-if="showedDate == null">Сортировать по дате</span>
          <span v-else>{{ showedDate }}</span>
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4 8H20"
              stroke="black"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16 3V5"
              stroke="black"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 3V5"
              stroke="black"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M3 6C3 4.89543 3.89543 4 5 4H19C20.1046 4 21 4.89543 21 6V19C21 20.1046 20.1046 21 19 21H5C3.89543 21 3 20.1046 3 19V6Z"
              stroke="black"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16 16.5H16.002V16.502H16V16.5Z"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12 16.5H12.002V16.502H12V16.5Z"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 16.5H8.002V16.502H8V16.5Z"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16 12.5H16.002V12.502H16V12.5Z"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12 12.5H12.002V12.502H12V12.5Z"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M8 12.5H8.002V12.502H8V12.5Z"
              stroke="black"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
      </v-calendar>
    </div>
    <div class="bills-container">
      <div v-for="bill in bills" class="bill-item">
        <div>
          <p class="bill-item__label">Имя</p>
          <a :href="bill.link" class="bill-item__file" target="_blank">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M7 21C5.89543 21 5 20.1046 5 19V5C5 3.89543 5.89543 3 7 3H14L19 8V19C19 20.1046 18.1046 21 17 21H7Z"
                stroke="#035AA6"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path d="M13 3V9H19" stroke="#035AA6" stroke-width="1.5" stroke-linejoin="round" />
            </svg>
            <span>{{ bill.name }}</span>
          </a>
        </div>
        <div>
          <p class="bill-item__label">Дата счёта</p>
          <p class="bill-item__date">{{ bill.date }}</p>
        </div>
        <div>
          <p class="bill-item__label">Номер заказа</p>
          <p class="bill-item__date">{{ bill.order }}</p>
        </div>
        <div>
          <p class="bill-item__label">Статус</p>
          <status-block
            :text="storeGetStatusText(bill.status)"
            :code="bill.status"
            :type="'bills'"
          ></status-block>
        </div>
        <router-link
          :to="{ name: 'order-item', params: { id: bill.order } }"
          class="bill-item__button"
        >
          <svg
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M3 12H21"
              stroke="black"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M16 7L21 12L16 17"
              stroke="black"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </router-link>
      </div>
    </div>
    <v-pagination
      class="catalog-page__pagination"
      v-show="isPagination"
      :totalPages="getTotalPages"
      :currentPage="currentPage"
      @page-change="pageChange"
    ></v-pagination>
  </section>
</template>

<script>
import StatusBlock from '../StatusBlock.vue'
import vCalendar from '../v-calendar.vue'
import vPagination from '../v-pagination.vue'

import { mapState, mapActions } from 'pinia'
import { serverRequest } from '../../api/request'

import { useOrderStatuses } from '@/stores/orderStatuses'

export default {
  name: 'BillBlock',

  components: {
    StatusBlock,
    vCalendar,
    vPagination
  },

  props: {
    userToken: {
      type: String,
      default() {
        return ''
      }
    }
  },

  emits: ['setLoading'],

  data() {
    return {
      searched: false,
      searchValue: '',
      date: null,
      showedDate: null,
      amount: 10,
      status: null,
      allBills: 0,
      currentPage: 1,
      bills: []
    }
  },

  beforeMount() {
    this.getData()
  },

  computed: {
    ...mapState(useOrderStatuses, { storeStatues: 'statuses' }),

    isPagination() {
      if (this.allBills > this.amount) {
        return true
      } else {
        return false
      }
    },

    getTotalPages() {
      if (this.isPagination) {
        return Math.ceil(this.allBills / this.amount)
      } else {
        return 1
      }
    }
  },

  methods: {
    ...mapActions(useOrderStatuses, { storeGetStatusText: 'getStatusText' }),

    getTextStatus(status) {
      switch (status) {
        case 1:
          return 'Ожидает подтверждения'
        case 2:
          return 'Ожидает оплаты'
        case 3:
          return 'Оплачен'
        case 4:
          return 'Просрочен'
        default:
          return 'Статус'
      }
    },

    onDateChange(date) {
      this.date = date

      let start_date =
        this.date[0].getDate().toString().padStart(2, '0') +
        '.' +
        parseInt(this.date[0].getMonth() + 1)
          .toString()
          .padStart(2, '0') +
        '.' +
        this.date[0].getFullYear()
      let end_date =
        this.date[1].getDate().toString().padStart(2, '0') +
        '.' +
        parseInt(this.date[1].getMonth() + 1)
          .toString()
          .padStart(2, '0') +
        '.' +
        this.date[1].getFullYear()

      this.showedDate = start_date + ' - ' + end_date

      this.currentPage = 1

      this.getData()
    },

    onDateClear() {
      this.date = null
      this.showedDate = null

      this.currentPage = 1

      this.getData()
    },

    blurHandler() {
      if (this.searchValue == '' && this.searched == true) {
        this.currentPage = 1
        this.searched = false
        this.getData()
      }
    },

    searchBills() {
      this.currentPage = 1
      this.searched = true

      if (this.searchValue != '') {
        let status = this.storeStatues.find((status) =>
          status.text.toLowerCase().includes(this.searchValue.toLowerCase())
        )

        if (status != undefined) {
          this.status = status.code
        } else {
          this.status = null
        }
      } else {
        this.status = null
      }

      this.getData()
    },

    pageChange(page) {
      this.currentPage = page

      window.scrollTo({ top: 200, behavior: 'smooth' })
      this.getData()
    },

    async getData() {
      this.$emit('setLoading', true)

      let formData = {
        search_value: this.searchValue,
        start_date: null,
        status: null,
        end_date: null,
        start_pos: this.amount * (this.currentPage - 1),
        amount: this.amount
      }

      if (this.date != null) {
        formData.start_date =
          this.date[0].getDate() +
          '.' +
          parseInt(this.date[0].getMonth() + 1) +
          '.' +
          this.date[0].getFullYear()
        formData.end_date =
          this.date[1].getDate() +
          '.' +
          parseInt(this.date[1].getMonth() + 1) +
          '.' +
          this.date[1].getFullYear()
      }

      if (this.status != null) {
        formData.status = this.status
      }

      console.log(formData)

      let result = await serverRequest('POST', 'json', 'documents/get_checks', formData)

      if (result != 'error' && result != 'unathorized') {
        this.allBills = result.bills_count
        this.bills = result.bills

        this.$emit('setLoading', false)
      } else {
        //alert('Ошибка запроса')
      }
    }
  }
}
</script>

<style lang="scss">
.bills-block {
  margin-top: 24px;

  .filter-container {
    display: flex;
    align-items: center;
    column-gap: 32px;
  }

  .date-pick {
    cursor: pointer;

    min-width: 230px;

    display: flex;
    align-items: center;
    column-gap: 12px;

    padding: 13px 16px;

    background-color: var(--bw4);

    border-radius: 4px;

    transition: background-color 0.3s;

    span {
      @extend %text-regular;
      color: var(--bw0);
      white-space: nowrap;
    }

    &:hover {
      background-color: var(--primary-bg);
    }
  }

  .search-input-container {
    position: relative;

    width: 100%;

    input {
      width: 100%;

      @extend %text-regular;
      color: var(--bw0);

      &::placeholder {
        @extend %text-regular;
        color: var(--bw3);
      }

      padding: 12px 44px 12px 16px;

      border-radius: 6px;
      border: 1px solid var(--bw4);

      background-color: var(--bw4);

      transition: border 0.3s;

      &:focus {
        border-color: var(--bw0);
      }
    }

    svg {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      cursor: pointer;
      right: 16px;
    }
  }

  .bills-container {
    margin-top: 32px;

    display: flex;
    flex-direction: column;
    row-gap: 12px;
  }

  .bill-item {
    padding: 16px 24px;

    border-radius: 6px;
    border: 1px solid $bw3;

    display: flex;
    align-items: center;
    column-gap: 24px;

    > div {
      width: 100%;

      display: flex;
      flex-direction: column;
      row-gap: 8px;
    }

    &__file {
      display: flex;
      align-items: center;
      column-gap: 8px;

      span {
        @extend %text-regular;
        color: var(--blue);
      }

      svg {
        flex-shrink: 0;
      }
    }

    &__button {
      min-width: 48px;
      width: 48px;
      max-width: 48px;

      height: 48px;

      padding: 12px;

      border-radius: 4px;

      background-color: $bw4;
    }
  }
}

@media screen and (max-width: 800px) {
  .bills-block {
    .bill-item {
      &__top {
        flex-direction: column;
        row-gap: 24px;
        align-items: flex-start;
      }
    }
  }
}

@media screen and (max-width: 600px) {
  .bills-block {
    .filter-container {
      flex-direction: column;
      row-gap: 16px;
      align-items: flex-start;
    }
  }
}
</style>
