<template>
  <div>
    <div v-if="!isMobile" class="payments-page">
      <custom-header class="title" :title="'платежи'"></custom-header>
      <div class="indicators-container">
        <div class="indicator-block" :class="{ loading: advancePayments.isLoading }">
          <v-loading :is-loading="advancePayments.isLoading" :color="'white'"></v-loading>
          <div>
            <p class="indicator-block__label">Авансовые платежи</p>
            <p
              v-if="advancePayments.sum != '' && advancePayments.sum != undefined"
              class="indicator-block__value"
            >
              {{ parseInt(advancePayments.sum).toLocaleString('ru-RU') }} ₽
            </p>
            <p v-else class="indicator-block__value">Информации нет</p>
            <!-- <alert-block
              v-if="
                advancePayments.next_payment_sum != '' &&
                advancePayments.next_payment_date != '' &&
                advancePayments.next_payment_sum != undefined
              "
              class="indicator-block__alert"
              :text="`Ближайший платеж на сумму ${advancePayments.next_payment_sum} ₽ состоится ${advancePayments.next_payment_date} по заказу №${advancePayments.next_payment_order}`"
            ></alert-block> -->
          </div>
          <router-link
            v-if="
              advancePayments.next_payment_order != null && advancePayments.next_payment_order != 0
            "
            class="indicator-block__link"
            :to="{ name: 'order-history' }"
          >
            <span>Перейти к заказам</span>
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.5 12H21.5"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.5 7L21.5 12L16.5 17"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </router-link>
        </div>
        <div class="indicator-block" :class="{ loading: receivablePayments.isLoading }">
          <v-loading :is-loading="receivablePayments.isLoading" :color="'white'"></v-loading>
          <div>
            <p class="indicator-block__label">Дебиторская задолженность</p>
            <p
              v-if="receivablePayments.sum != '' && receivablePayments.sum != undefined"
              class="indicator-block__value"
            >
              {{ parseInt(receivablePayments.sum).toLocaleString('ru-RU') }} ₽
            </p>
            <p v-else class="indicator-block__value">Отсутствует</p>
            <!-- <alert-block
              class="indicator-block__alert"
              v-if="
                receivablePayments.next_payment_sum != '' &&
                receivablePayments.next_payment_date != '' &&
                receivablePayments.next_payment_sum != undefined
              "
              :text="`Ближайший платеж на сумму ${receivablePayments.next_payment_sum} ₽ состоится ${receivablePayments.next_payment_date} по заказу №${receivablePayments.next_payment_order}`"
            ></alert-block> -->
          </div>
          <router-link
            v-if="
              receivablePayments.next_payment_order != null &&
              receivablePayments.next_payment_order != 0
            "
            class="indicator-block__link"
            :to="{ name: 'order-history' }"
          >
            <span>Перейти к заказам</span>
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.5 12H21.5"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.5 7L21.5 12L16.5 17"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </router-link>
        </div>
        <div class="indicator-block" :class="{ loading: overdueReceivablePayments.isLoading }">
          <v-loading :is-loading="overdueReceivablePayments.isLoading" :color="'white'"></v-loading>
          <div>
            <p class="indicator-block__label">Просроченная дебиторская задолженность</p>
            <p
              v-if="
                overdueReceivablePayments.sum != '' && overdueReceivablePayments.sum != undefined
              "
              class="indicator-block__value"
            >
              {{ parseInt(overdueReceivablePayments.sum).toLocaleString('ru-RU') }} ₽
            </p>
            <p v-else class="indicator-block__value">Отсутствует</p>
            <!-- <alert-block
              class="indicator-block__alert"
              v-if="
                overdueReceivablePayments.next_payment_sum != '' &&
                overdueReceivablePayments.next_payment_date != '' &&
                overdueReceivablePayments.next_payment_sum != undefined
              "
              :text="`Ближайший платеж на сумму ${overdueReceivablePayments.next_payment_sum} ₽ состоится ${overdueReceivablePayments.next_payment_date} по заказу №${overdueReceivablePayments.next_payment_order}`"
            ></alert-block> -->
          </div>
          <router-link
            v-if="
              overdueReceivablePayments.next_payment_order != null &&
              overdueReceivablePayments.next_payment_order != 0
            "
            class="indicator-block__link"
            :to="{
              name: 'order-history'
            }"
          >
            <span>Перейти к заказам</span>
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.5 12H21.5"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M16.5 7L21.5 12L16.5 17"
                stroke="white"
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>
          </router-link>
        </div>
      </div>
      <div class="payments-table-container" :class="{ loading: soonPaymentsLoading }">
        <v-loading :is-loading="soonPaymentsLoading" :color="'white'"></v-loading>
        <div class="table-header">
          <p>Предстоящие платежи</p>
          <div class="switch-container">
            <v-switch :options="soonSwitchOptions" @switched="onSoonSwitch"></v-switch>
          </div>
        </div>
        <table v-if="filteredSoonPayments.length > 0">
          <tr>
            <th class="left order">Заказ</th>
            <th class="check number">Счёт</th>
            <th class="date">Дата заказа</th>
            <th class="payment-date">
              <div class="date">
                <svg
                  width="25"
                  height="24"
                  viewBox="0 0 25 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M4.5 8H20.5"
                    stroke="#D93030"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.5 3V5"
                    stroke="#D93030"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.5 3V5"
                    stroke="#D93030"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M3.5 6C3.5 4.89543 4.39543 4 5.5 4H19.5C20.6046 4 21.5 4.89543 21.5 6V19C21.5 20.1046 20.6046 21 19.5 21H5.5C4.39543 21 3.5 20.1046 3.5 19V6Z"
                    stroke="#D93030"
                    stroke-width="1.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.5 16.5H16.502V16.502H16.5V16.5Z"
                    stroke="#D93030"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.5 16.5H12.502V16.502H12.5V16.5Z"
                    stroke="#D93030"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.5 16.5H8.502V16.502H8.5V16.5Z"
                    stroke="#D93030"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M16.5 12.5H16.502V12.502H16.5V12.5Z"
                    stroke="#D93030"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M12.5 12.5H12.502V12.502H12.5V12.5Z"
                    stroke="#D93030"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M8.5 12.5H8.502V12.502H8.5V12.5Z"
                    stroke="#D93030"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
                <span>Срок платежа</span>
              </div>
            </th>
            <th class="delay">Срок просрочки</th>
            <th class="sum">Сумма</th>
            <th class="left status">Статус</th>
            <th class="buttons"></th>
          </tr>
          <payment-row
            v-for="payment in filteredSoonPayments"
            :number="parseInt(payment.order)"
            :date="payment.order_date"
            :payment-term="payment.payment_date"
            :delay="payment.payment_late"
            :sum="parseInt(payment.sum)"
            :status="storeGetStatus(payment.status[0])"
          ></payment-row>
        </table>
        <p v-else class="payments-table-container__text">Платежей не найдено</p>
        <button v-if="filteredSoonPayments.length > 5" @click="showAllRows" class="show-btn">
          <span class="not-shown">Развернуть</span>
          <span class="shown">Свернуть</span>
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.5 11L12.5 16L17.5 11"
              stroke="#035AA6"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
      <div class="payments-table-container" :class="{ loading: archivePaymentsLoading }">
        <v-loading :is-loading="archivePaymentsLoading" :color="'white'"></v-loading>
        <div class="table-header">
          <p>Архивные платежи</p>
          <div class="switch-container">
            <v-switch :options="archiveSwitchOptions" @switched="onArchiveSwitch"></v-switch>
          </div>
        </div>
        <table v-if="filteredArchivePayments.length > 0" class="archive-payments">
          <tr>
            <th class="left">Номер заказа</th>
            <th>Дата заказа</th>
            <th>Срок просрочки</th>
            <th class="sum">Сумма</th>
            <th class="left status">Статус</th>
          </tr>
          <payment-row
            v-for="payment in filteredArchivePayments"
            :number="parseInt(payment.order)"
            :date="payment.order_date"
            :payment-term="'none'"
            :delay="payment.payment_late"
            :sum="parseInt(payment.sum)"
            :status="storeGetStatus(payment.status[0])"
          ></payment-row>
        </table>
        <p v-else class="payments-table-container__text">Платежей не найдено</p>
        <button v-if="filteredArchivePayments.length > 5" @click="showAllRows" class="show-btn">
          <span class="not-shown">Развернуть</span>
          <span class="shown">Свернуть</span>
          <svg
            width="25"
            height="24"
            viewBox="0 0 25 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.5 11L12.5 16L17.5 11"
              stroke="#035AA6"
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </button>
      </div>
    </div>
    <mobile-warning v-else></mobile-warning>
  </div>
</template>

<script>
import CustomHeader from '../components/CustomHeader.vue'
import AlertBlock from '../components/AlertBlock.vue'
import vSwitch from '../components/v-switch.vue'
import PaymentRow from '../components/PaymentRow.vue'
import vLoading from '../components/v-loading.vue'
import MobileWarning from '../components/MobileWarning.vue'

import { mapState, mapActions, mapWritableState } from 'pinia'
import { useOrderStatuses } from '@/stores/orderStatuses'
import { useBreadcrumbs } from '../stores/breadcrumbs'

import { serverRequest } from '../api/request'

export default {
  name: 'PaymentsView',
  components: {
    CustomHeader,
    AlertBlock,
    vSwitch,
    PaymentRow,
    vLoading,
    MobileWarning
  },

  data() {
    return {
      isMobile: false,

      activeSoonType: 'all',
      activeArchiveType: 'all',

      soonPaymentsLoading: false,
      archivePaymentsLoading: false,

      soonPaymentsShown: false,

      advancePayments: {
        isLoading: false,
        sum: '',
        next_payment_sum: '',
        next_payment_date: '',
        next_payment_order: null
      },

      receivablePayments: {
        isLoading: false,
        sum: '',
        next_payment_sum: '',
        next_payment_date: '',
        next_payment_order: null
      },

      overdueReceivablePayments: {
        isLoading: false,
        sum: '',
        next_payment_sum: '',
        next_payment_date: '',
        next_payment_order: null
      },

      soonSwitchOptions: [
        {
          id: 's1',
          name: 'payments-type',
          value: 'all',
          label: 'Все',
          checked: true
        },
        {
          id: 's2',
          name: 'payments-type',
          value: 'prepayments',
          label: 'Авансовые платежи',
          checked: false
        },
        {
          id: 's3',
          name: 'payments-type',
          value: 'debitor',
          label: 'Дебиторская задолженность',
          checked: false
        }
      ],

      archiveSwitchOptions: [
        {
          id: 'as1',
          name: 'archive-payments-type',
          value: 'all',
          label: 'Все',
          checked: true
        },
        {
          id: 'as2',
          name: 'archive-payments-type',
          value: 'prepayments',
          label: 'Авансовые платежи',
          checked: false
        },
        {
          id: 'as3',
          name: 'archive-payments-type',
          value: 'debitor',
          label: 'Дебиторская задолженность',
          checked: false
        }
      ],

      soonPayments: [
        // {
        //   type: 1,
        //   number: 7645678,
        //   date: '20/01/2020 type1',
        //   paymentTerm: '21/01/2020',
        //   delay: 654,
        //   sum: 12000,
        //   status: 0
        // },
        // {
        //   type: 0,
        //   number: 7645678,
        //   date: '20/01/2020 type0',
        //   paymentTerm: '21/01/2020',
        //   delay: 654,
        //   sum: 12000,
        //   status: 2
        // },
      ],

      archivePayments: [
        // {
        //   type: 1,
        //   number: 7645678,
        //   date: '20/01/2020 type1',
        //   paymentTerm: '21/01/2020',
        //   delay: 654,
        //   sum: 12000,
        //   status: 4
        // },
        // {
        //   type: 0,
        //   number: 7645678,
        //   date: '20/01/2020 type0',
        //   paymentTerm: '21/01/2020',
        //   delay: 654,
        //   sum: 12000,
        //   status: 3
        // },
      ]
    }
  },

  beforeMount() {
    this.storeBreadcrumbs = [
      {
        path: 'payments',
        name: 'Платежи'
      }
    ]

    if (document.documentElement.clientWidth <= 600) {
      this.isMobile = true
    }

    this.getAdvancePayments()
    this.getOverdueReceivablePayments()
    this.getReceivablePayments()
    this.getAdvanceHistory()
    this.getArchiveHistory()
  },

  computed: {
    ...mapWritableState(useBreadcrumbs, { storeBreadcrumbs: 'breadcrumbs' }),

    filteredSoonPayments() {
      if (this.activeSoonType == 'all') {
        return this.soonPayments
      }
      if (this.activeSoonType == 'prepayments') {
        return this.soonPayments.filter((item) => item.type != 1)
      }
      if (this.activeSoonType == 'debitor') {
        return this.soonPayments.filter((item) => item.type != 0)
      }
    },

    filteredArchivePayments() {
      if (this.activeArchiveType == 'all') {
        return this.archivePayments
      }
      if (this.activeArchiveType == 'prepayments') {
        return this.archivePayments.filter((item) => item.type != 1)
      }
      if (this.activeArchiveType == 'debitor') {
        return this.archivePayments.filter((item) => item.type != 0)
      }
    }
  },

  methods: {
    ...mapActions(useOrderStatuses, { storeGetStatus: 'getStatus' }),

    showAllRows(event) {
      let item = event.target.closest('.payments-table-container')
      if (item.classList.contains('show')) {
        item.classList.remove('show')
      } else {
        item.classList.add('show')
      }
    },

    onSoonSwitch(value) {
      this.activeSoonType = value
    },

    onArchiveSwitch(value) {
      this.activeArchiveType = value
    },

    async getPaymentInfo(type) {
      let formData = {
        type: type
      }

      let result = await serverRequest('POST', 'json', 'payments/get_payment', formData)

      if (result != 'error' && result != 'unathorized') {
        return result
      } else {
        //alert('Ошибка запроса')
      }
    },

    async getAdvancePayments() {
      this.advancePayments.isLoading = true
      this.advancePayments = await this.getPaymentInfo(0)

      this.advancePayments.isLoading = false
    },

    async getReceivablePayments() {
      this.receivablePayments.isLoading = true
      this.receivablePayments = await this.getPaymentInfo(1)
      this.receivablePayments.isLoading = false
    },

    async getOverdueReceivablePayments() {
      this.overdueReceivablePayments.isLoading = true
      this.overdueReceivablePayments = await this.getPaymentInfo(2)
      this.overdueReceivablePayments.isLoading = false
    },

    async getAdvanceHistory() {
      this.soonPaymentsLoading = true

      let result = await serverRequest('GET', 'json', 'payments/get_soon_payments')

      if (result != 'error' && result != 'unathorized') {
        this.soonPayments = result.payments
      } else {
        //alert('Ошибка запроса')
      }

      this.soonPaymentsLoading = false
    },

    async getArchiveHistory() {
      this.archivePaymentsLoading = true

      let result = await serverRequest('GET', 'json', 'payments/get_archive_payments')

      if (result != 'error' && result != 'unathorized') {
        this.archivePayments = result.payments
      } else {
        //alert('Ошибка запроса')
      }

      this.archivePaymentsLoading = false
    }
  }
}
</script>

<style lang="scss">
.payments-page {
  @extend %white-bg-block;

  width: 100%;

  .title {
    width: fit-content;

    &::after {
      width: 100%;
    }
  }

  .indicators-container {
    margin-top: 32px;

    display: flex;
    column-gap: 16px;
  }

  .indicator-block {
    padding: 16px;

    width: 100%;

    border-radius: 6px;
    border: 1px solid $bw3;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    position: relative;

    &.loading {
      pointer-events: none;
    }

    &__label {
      @extend %text-regular;
      color: $bw1;
    }

    &__value {
      margin-top: 12px;

      @extend %DB;
      color: $bw0;
    }

    &__alert {
      margin-top: 24px;
    }

    &__link {
      margin-top: 24px;

      @extend %button;

      display: flex;
      column-gap: 12px;
      align-items: center;
      justify-content: center;
    }
  }

  .payments-table-container {
    margin-top: 24px;

    padding: 16px 24px;

    border: 1px solid $bw3;

    border-radius: 6px;

    position: relative;

    &.loading {
      pointer-events: none;
    }

    &__text {
      margin-top: 32px;

      @extend %text-medium;
    }

    table {
      margin-top: 28px;

      width: 100%;

      border-collapse: collapse;

      th {
        padding: 12px 0;

        width: auto;

        &:last-of-type {
          margin-right: 0;
        }

        text-align: center;

        @extend %text-regular;
        color: $bw0;

        &.left {
          text-align: left;
        }

        &.order {
          width: 90px;
        }

        &.number {
          width: 120px;
        }

        &.sum {
          width: 200px;
        }

        &.status {
          text-align: center;
          //width: 150px;
        }

        // &.date {
        //   width: 200px;
        // }

        &.payment-date {
          width: 250px;
        }

        // &.delay {
        //   width: 200px;
        // }

        .date {
          display: flex;
          column-gap: 8px;
          align-items: center;
          justify-content: center;

          span {
            @extend %text-regular;
            color: $bw0;
          }
        }
      }

      tr:nth-child(n + 7) {
        display: none;
      }

      &.archive-payments {
        th {
          width: 18%;
        }
      }
    }

    .show-btn {
      display: flex;
      column-gap: 8px;
      align-items: center;

      width: fit-content;
      margin: 16px auto 0;

      background-color: transparent;

      span {
        @extend %text-regular;
        color: var(--blue);
      }

      svg {
        transition: transform 0.3s;
      }

      .not-shown {
        display: block;
      }

      .shown {
        display: none;
      }
    }

    &.show {
      table {
        tr:nth-child(n + 7) {
          display: table-row;
        }
      }

      .show-btn {
        .not-shown {
          display: none;
        }

        .shown {
          display: block;
        }

        svg {
          transform: rotate(180deg);
        }
      }
    }
  }

  .table-header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    p {
      @extend %H2;
      color: $bw0;
    }

    .switch-container {
      border: 1px solid $bw3;
      border-radius: 6px;
    }
  }
}

@media screen and (max-width: 800px) {
  .payments-page {
    .indicators-container {
      flex-direction: column;
      row-gap: 16px;
    }

    .payments-table-container {
      .table-header {
        flex-direction: column;
        row-gap: 12px;
        align-items: flex-start;
      }
    }
  }
}
</style>
