import { defineStore } from 'pinia'
import { ref, watch } from 'vue'

export const useSettings = defineStore('settings', () => {
  const isMarketingActive = ref(false)
  const isKnowledgeActive = ref(false)
  const isSpecialOrderActive = ref(false)

  const storageMarketingActive = sessionStorage.getItem('isMarketingActive')
  const storageKnowledgeActive = sessionStorage.getItem('storageKnowledgeActive')
  const storageSpecialOrderActive = sessionStorage.getItem('storageSpecialOrderActive')

  if (storageMarketingActive) {
    isMarketingActive.value = JSON.parse(storageMarketingActive)
  }

  if (storageKnowledgeActive) {
    isKnowledgeActive.value = JSON.parse(storageKnowledgeActive)
  }

  if (storageSpecialOrderActive) {
    isSpecialOrderActive.value = JSON.parse(storageSpecialOrderActive)
  }

  watch(isMarketingActive, async (newStatus) => {
    sessionStorage.setItem('isMarketingActive', JSON.stringify(newStatus))
  })

  watch(storageKnowledgeActive, async (newStatus) => {
    sessionStorage.setItem('storageKnowledgeActive', JSON.stringify(newStatus))
  })

  watch(isSpecialOrderActive, async (newStatus) => {
    sessionStorage.setItem('storageSpecialOrderActive', JSON.stringify(newStatus))
  })

  return {
    isMarketingActive,
    isKnowledgeActive,
    isSpecialOrderActive
  }
})
